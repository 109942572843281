import { Button, Card, Col, Form, Image, Overlay, Popover } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import './Medium.module.scss'
import { useHotkeys } from "react-hotkeys-hook";
import _ from 'lodash'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { mediumsConstants } from "../../constants";
import ReactPlayer from 'react-player'
import { projectsConstants } from "../../constants";
// import ReactAudioPlayer from 'react-audio-player';

function GetMediaUrl(media, project) {
    const server = process.env.REACT_APP_BASE_URL;
    let mediaUrl = !media['note'].includes(mediumsConstants.TEST_IMAGE) ?
        `${server}/storage/media/${project.id}/${media['media_path']}` :
        `${server}/storage/media/test/${media['media_path']}`;

    if (project.name === projectsConstants.VID_STREAMING && !media['note'].includes(mediumsConstants.TEST_IMAGE)) {
        mediaUrl = media['media_url'];
    }

    return mediaUrl;
}

const Media = ({ media, activeLabelId, labels, onMouseClick, project, updateMediaInfo, category }) => {
    const [activeLabelColor, setActiveLabelColor] = useState(null);
    const [isMediaOpacity, setMediaOpacity] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [mediaLoaded, setMediaLoaded] = useState(null);
    const [mediaErrored, setMediaErrored] = useState(null);
    // const server = process.env.REACT_APP_BASE_URL;
    // const mediaUrl = !media['note'].includes(mediumsConstants.TEST_IMAGE) ? 
    //                 `${server}/storage/media/${project.id}/${media['media_path']}` : 
    //                 `${server}/storage/media/test/${media['media_path']}`;

    const mediaUrl = GetMediaUrl(media, project);

    let mediaType = media['media_type'];
    useEffect(() => {
        const label = _.find(labels, el => el.id === activeLabelId);
        if (!label) {
            return
        }
        setActiveLabelColor(label.color);
        setMediaOpacity(label.opacity);
        if (label.opacity) {
            setOpacity(0.4)
        } else {
            setOpacity(1)
        }

    }, [activeLabelId, labels]);

    const getMediaInfo = () => {
        let result = 'Video';
        let mediaType = media['media_type']
        if (mediaType === mediumsConstants.IMAGE) {
            result = 'Image';
        } else if (mediaType === mediumsConstants.GIF) {
            result = 'Gif'
        } else if (mediaType === mediumsConstants.AUDIO) {
            result = 'Audio'
        }
        return result
    };

    const loaded = () => {
        if (!mediaLoaded) {
            setMediaLoaded(true);
            updateMediaInfo(getMediaInfo() + ' | 100% Loaded');
        }
    };

    const errored = (ev) => {
        if (!mediaErrored) {
            updateMediaInfo(getMediaInfo() + ' | Failed To Load');
            setMediaErrored(true)
        }

        if (project.name === projectsConstants.RACECAR) {
            ev.target.src = "/race_car_no_photo.png";
        }

    };
    let content;

    if (project.name === projectsConstants.LOVE_AUDIO) {
        mediaType = mediumsConstants.AUDIO;
    }
    if (mediaType === mediumsConstants.IMAGE || mediaType === mediumsConstants.GIF) {
        content = <Image onLoad={loaded} onError={errored} variant="top" src={mediaUrl}
            className={`img-fluid ${isMediaOpacity ? 'media-opacity' : ''}`} />

    } else if (mediaType === mediumsConstants.VIDEO) {
        content =
            <ReactPlayer controls onError={errored} onReady={loaded} url={mediaUrl} playing width="100%" height="100%" loop muted
                style={{ opacity: opacity }} />
    } else if (mediaType === mediumsConstants.AUDIO) {
        // content =
        //     <ReactAudioPlayer
        //         src={mediaUrl}
        //         // autoPlay
        //         controls
        //         onError={errored} onReady={loaded}
        //         width="100%" height="100%"
        //         style={{ opacity: opacity }} 
        //     />
    }


    let isIS1AlbumHash = false;
    if (project.name === projectsConstants.IS1_ALBUM) {
        if (media['note'] != null) {
            let media_note = media['note'].split(" | ");
            if (media_note[0] != null && media_note[0] != "" && media_note[0] == "album_hash") {
                isIS1AlbumHash = true;
            }
        }
    }

    if (isIS1AlbumHash) {
        return (
            <div></div>
        )
    }
    else {
        return (
            <Card.Header onClick={onMouseClick} className={`p-0 ${category} ${project.name}`} style={{ borderColor: activeLabelColor }}>
                {/*<div onClick={onMouseClick} style={{backgroundImage: `url(${mediaUrl})`}} className={`media-bg-container ${isMediaOpacity? 'media-opacity': ''}`}>*/}
                {/*</div>*/}
                {content}
            </Card.Header>
        )
    }
};

const ActiveLabelText = ({ activeLabelId, labels }) => {
    const [activeLabelName, setActiveLabelName] = useState(null);
    const [activeLabelColor, setActiveLabelColor] = useState(null);
    useEffect(() => {
        const label = _.find(labels, el => el.id === activeLabelId);
        let result = null;
        if (!label) {
            return
        }
        if (label) {
            if (label.name == "ApproveImageAndDescription" || 
                label.name == "RejectDueToImage" || 
                label.name == "RejectDueToDescription" || 
                label.name == "RejectDueToImageAndDescription") {
                    result = label.name.replace(/([A-Z])/g, ' $1').trim()
            } else {
                result = label.name.toUpperCase()
            }
        }
        setActiveLabelName(result);
        setActiveLabelColor(label.color)
    }, [activeLabelId, labels]);
    return (
        <Button className="bg-transparent border-0 "
            style={{ color: activeLabelColor }}><strong>{activeLabelName}</strong></Button>
    )
};

const HeaderMediaId = ({ media, mediaInfo, project }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [info, setInfo] = useState(null);
    let classNameInfo = 'text-danger'
    const mediaType = media['media_type']
    if (mediaType === mediumsConstants.GIF || mediaType === mediumsConstants.VIDEO) {
        classNameInfo = 'rainbow-text'
    }

    useEffect(() => {
        let result;
        if (mediaInfo) {
            result = mediaInfo.join(' | ');
        }
        setInfo(result)
    }, [mediaInfo]);

    const hideOverlay = () => {
        setTimeout(() => {
            setShow(false)
        }, 500)
    }

    let projectName = project.name.replace(/ /g, "-")

    return (
        <div className="d-flex justify-content-between">
            <CopyToClipboard text={media['media_id']} onCopy={() => setShow(true)}>
                <Button ref={target} className={"bg-transparent border-0 shadow-none media-info-text pt-0 pb-1 " + projectName + "-media-info-text"}>{media['media_id']}</Button>
            </CopyToClipboard>
            <Overlay target={target.current} placement="bottom" show={show} rootClose onHide={() => hideOverlay()}>
                <Popover id="popover-basic">
                    <Popover.Title as="h3" className="text-black-50">Copied</Popover.Title>
                </Popover>
            </Overlay>
            <Button className={"bg-transparent border-0 shadow-none media-info-text pt-0 pb-1 " + classNameInfo + " " + projectName + "-media-info-text"}><b>{info}</b></Button>
        </div>
    )
};


export const SingleMediaBox = ({ media, project, keyboard, position, hotkeys, handleFieldChange, openModal, is1AlbumCurrentIndexToShow }) => {
    
    let { labels } = project;
    let labelsIS1Hash = [];
    let firstLabel = labels[0].id;
    if (project.name === projectsConstants.IS1_ALBUM) {
        labelsIS1Hash = labels.filter(label => label.name === "Approve" || label.name === "Reject").map((label, index) => {     
            return label;
        })
        labels = labels.filter(label => label.name !== "Approve" && label.name !== "Reject").map((label, index) => {     
            return label;
        })
        
        let _isIS1AlbumHash = false;
        if (media['note'] != null) {
            let media_note = media['note'].split(" | ");
            if (media_note[0] != null && media_note[0] != "" && media_note[0] == "album_hash") {
                _isIS1AlbumHash = true;
            }
        }
        if (_isIS1AlbumHash) {
            firstLabel = labelsIS1Hash[0].id;
        } else {
            firstLabel = labels[0].id;
        }
    }

    // labelsIS1Image
    const [mediaInfo, setMediaInfo] = useState([]);
    const [activeLabelId, setActiveLabelId] = useState(firstLabel);
    const [storytellingLabelType, setStorytellingLabelType] = useState('Image');
    const [storytellingLabelImage, setStorytellingLabelImage] = useState('Okay');
    const storytellingLabelTypeHotkeys = ['q', 'w', 'e'];
    const storytellingLabelHotkeys = ['a', 's', 'd'];
    const storytellingLabelBackHotkeys = ['z', 'x', 'c'];
    const is1AlbumHotkeys = ['q', 'w', 'e'];
    const is1AlbumZoomkeys = ['shift+q', 'shift+w', 'shift+e'];
    const zoomKey = `shift+${keyboard}`;
    const openMediaKey = `shift+${position + 1}`;
    const [is1AlbumIsShowing, setIs1AlbumIsShowing] = useState(false);

    useEffect(() => {
        handleFieldChange(media.id, activeLabelId);
    }, [media, activeLabelId, handleFieldChange]);

   
    useHotkeys(zoomKey, () => {
        if (project.name !== projectsConstants.IS1_ALBUM) {
            openModal(media);
        }
    }, [media, openModal]);

    useHotkeys(is1AlbumZoomkeys.join(','), (event, handler) => {
        if (project.name === projectsConstants.IS1_ALBUM) {
            if (position !== 0) {   // Not Album Hash
                if (handler.key === is1AlbumZoomkeys[(position - 1) % 3] && is1AlbumCurrentIndexToShow - position >=0 && is1AlbumCurrentIndexToShow - position <= 2) {
                    openModal(media);
                }
            }
        } 
    }, [keyboard, media, openModal]);

    useHotkeys(openMediaKey, () => {
        window.open(media['media_url']);
    }, [media]);

    const changeLabel = (prev) => {
        if (prev === undefined) {
            prev = activeLabelId
        }

        let nextIndex = 0;
        let labelIndex;
        if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
            if (storytellingLabelImage === "Escalate") {
                setStorytellingLabelImage(labels[labels.length - 1].name);
                nextIndex = labels.length - 1;
            } else {
                let storytellingLabel = storytellingLabelType + "_" + storytellingLabelImage;
                labelIndex = _.findIndex(labels, el => el.name === storytellingLabel);
                nextIndex = labels[labelIndex + 1] ? labelIndex + 1 : 0;
                setStorytellingLabelImage(labels[nextIndex].name.split("_")[1]);
            }
        } else {
            labelIndex = _.findIndex(labels, el => el.id === prev);
        }

        if (labelIndex !== undefined) {
            nextIndex = labels[labelIndex + 1] ? labelIndex + 1 : 0
        }
        return labels[nextIndex].id
    };

    const changeLabelBackward = (prev) => {
        if (prev === undefined) {
            prev = activeLabelId
        }
        let nextIndex = 0;
        let labelIndex;
        if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
            if (storytellingLabelImage === "Okay") {
                setStorytellingLabelImage(labels[labels.length - 1].name);
                nextIndex = labels.length - 1;
            } else {
                let storytellingLabel = storytellingLabelType + "_" + storytellingLabelImage;
                labelIndex = _.findIndex(labels, el => el.name === storytellingLabel);
                nextIndex = labels[labelIndex - 1] ? labelIndex - 1 : 8
                setStorytellingLabelImage(labels[nextIndex].name.split("_")[1]);
            }
        } else {
            labelIndex = _.findIndex(labels, el => el.id === prev);
        }

        if (labelIndex !== undefined) {
            nextIndex = labels[labelIndex - 1] ? labelIndex - 1 : 0
        }
        return labels[nextIndex].id
    };

    useHotkeys(hotkeys.join(','), (event, handler) => {
        if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
            if (handler.key === storytellingLabelTypeHotkeys[position]) {
                setStorytellingLabelType(prev => {
                    if (prev === "Image") {
                        return "Illustration";
                    } else {
                        return "Image";
                    }
                });
            }
        } else if (project.name === projectsConstants.IS1_ALBUM) {
            if (position !== 0) {   // Not Album Hash
                if (handler.key === is1AlbumHotkeys[(position - 1) % 3] && is1AlbumCurrentIndexToShow - position >=0 && is1AlbumCurrentIndexToShow - position <= 2) {
                    setActiveLabelId(prev => {
                        return changeLabel(prev)
                    });
                }
            }
        } else {
            if (handler.key === keyboard) {
                setActiveLabelId(prev => {
                    return changeLabel(prev)
                });
            }
        }
    }, [keyboard, changeLabel, hotkeys]);

    useHotkeys(storytellingLabelHotkeys.join(','), (event, handler) => {
        if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
            if (handler.key === storytellingLabelHotkeys[position]) {
                setActiveLabelId(prev => {
                    return changeLabel(prev)
                });
            }
        }
    }, [keyboard, changeLabel, storytellingLabelHotkeys]);

    useHotkeys(storytellingLabelBackHotkeys.join(','), (event, handler) => {
        if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
            if (handler.key === storytellingLabelBackHotkeys[position]) {
                setActiveLabelId(prev => {
                    return changeLabelBackward(prev)
                });
            }
        }
    }, [keyboard, changeLabelBackward, storytellingLabelBackHotkeys]);

    // useHotkeys(storytellingLabelTypeHotkeys.join(','), (event, handler) => {
    //     if (handler.key === storytellingLabelTypeHotkeys[position]) {
    //         if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
    //             setStorytellingLabelType(prev => {
    //                 if (prev === "Image") {
    //                     return "Illustration";
    //                 } else {
    //                     return "Image";
    //                 }
    //             });
    //         }
    //     }
    // }, [storytellingLabelTypeHotkeys]);

    const handleRadioChange = (event, labelId) => {
        if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
            if (labelId === "Image" || labelId === "Illustration") {
                setStorytellingLabelType(labelId);
            } else {
                setStorytellingLabelImage(labelId);
            }
            if (labelId === "Broken") {
                for (var i = 0; i < labels.length; i++) {
                    if (labels[i].name === labelId) {
                        setActiveLabelId(labels[i].id);
                    }
                }
            }
            event.target.blur();
        } else {
            setActiveLabelId(labelId);
        }
    };

    useEffect(() => {
        if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
            labels.map((label, index) => {
                let storytellingLabel = storytellingLabelType + "_" + storytellingLabelImage;
                if (storytellingLabel == label.name) {
                    setActiveLabelId(label.id);
                }
            });
        }
    }, [storytellingLabelType, storytellingLabelImage, activeLabelId]);

    const handleMouseClickMedia = () => {
        setActiveLabelId(prev => changeLabel(prev))
    };
    const updateMediaInfo = (info) => {
        setMediaInfo(pre => {
            return [...pre, info]
        })
    };

    const onFocusRadio = (event, labelId) => {
        event.target.blur()
    }

    var note = '';
    const photoCategory = (url, project) => {
        if (project.name === 'Kids' && url.includes('/com/')) {
            // note = "Lưu ý:\n .";
            return 'us'
        }
        return 'other'
    }

    const decodeNote = (note) => {
        if (project.name === projectsConstants.RACECAR || project.name === projectsConstants.LOVE) {
            try {
                return JSON.parse(note)
            } catch (e) {
                return note
            }
        }
        return note
    }

    const currentLabel = _.find(labels, el => el.id === activeLabelId);

    let preContent = "";
    let postContent = "";
    let middleContent = "";
    if (project.name === projectsConstants.IS1_SINGLE || project.name === projectsConstants.RACECAR) {
        if (media['note'] != null) {
            let note = media['note'].split(" | ");
            if (note[0] != null && note[0] != "")
                preContent = <Card className="post-header">{note[0]}</Card>;
            else
                preContent = <Card className="post-header">N/A</Card>;

            if (note[1] != null && note[1] != "")
                postContent = <Card className={"post-header post-header-" + project.name}>{decodeNote(note[1])}</Card>;
            else
                postContent = <Card className={"post-header post-header-" + project.name}>N/A</Card>;

            if (note[2] != null && note[2] != "")
                if (note[2] == "0")
                    middleContent = <Card className={"middle-content-green-" + project.name}>reports count: {decodeNote(note[2])}</Card>;
                else
                    middleContent = <Card className={"middle-content-red-" + project.name}>reports count: {decodeNote(note[2])}</Card>;
            else
                middleContent = "";
        }
    }
    else if (project.name === projectsConstants.IS1_ALBUM) {
        if (media['note'] != null) {
            let note = media['note'].split(" | ");
            if (note[3] != null && note[3] != "")
                postContent = <Card className={"post-header post-header-" + project.name}>{decodeNote(note[3])}</Card>;
            else
                postContent = <Card className={"post-header post-header-" + project.name}>N/A</Card>;

        }
    }
    else if (project.name === projectsConstants.LOVE) {
        if (media['note'] != null && media['note'] != "") {
            postContent = <Card className={"post-header post-header-" + project.name}>{decodeNote(media['note'])}</Card>;
        } else {
            postContent = <Card className={"post-header post-header-" + project.name}>N/A</Card>;
        }
    }

    let labelType = "";
    let labelList = "";
    let isIS1AlbumHash = false;

    if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
        labelType =
            <Card className="bg-black">
                <Form.Group controlId={`control-type-1-${media.id}`} className="mb-1">
                    <Form.Check
                        name={`group-type-${media.id}`}
                        type="radio"
                        label="Image"
                        // defaultChecked={true}
                        checked={"Image" === storytellingLabelType}
                        onChange={(event) => handleRadioChange(event, "Image")}
                    // onFocus={(event) => onFocusRadio(event, "ImageType")}
                    />
                </Form.Group>
                <Form.Group controlId={`control-type-2-${media.id}`} className="mb-1">
                    <Form.Check
                        name={`group-type-${media.id}`}
                        type="radio"
                        label="Illustration"
                        // defaultChecked={false}
                        checked={"Illustration" === storytellingLabelType}
                        onChange={(event) => handleRadioChange(event, "Illustration")}
                    // onFocus={(event) => onFocusRadio(event, "IllustrationType")}
                    />
                </Form.Group>
            </Card>

        labelList = labels.map((label, index) => {
            let labelName = label.name.replace("Image_", "");
            if (label.name.includes("Image_") || label.name === "Broken") {
                return <Form.Group controlId={`control-${label.id}-${media.id}`} className="mb-1"
                    key={`group-label-${index}`}>
                    <Form.Check
                        name={`group-${media.id}`}
                        type="radio"
                        label={labelName}
                        checked={labelName === storytellingLabelImage}
                        onChange={(event) => handleRadioChange(event, labelName)}
                        onFocus={(event) => onFocusRadio(event, label.id)}
                    />
                </Form.Group>
            }
        })

    }
    else if (project.name === projectsConstants.IS1_ALBUM) {
        if (media['note'] != null) {
            let media_note = media['note'].split(" | ");
            if (media_note[0] != null && media_note[0] != "" && media_note[0] == "album_hash") {
                isIS1AlbumHash = true;
            }
        }

        if (isIS1AlbumHash) {
            labelList = labelsIS1Hash.map((label, index) => {
                if (label.name === "Approve" || label.name === "Reject")
                    return <Form.Group controlId={`control-${label.id}-${media.id}`} className="mb-1"
                        key={`group-label-${index}`}>
                        <Form.Check
                            name={`group-${media.id}`}
                            type="radio"
                            label={label.name}
                            checked={activeLabelId === label.id}
                            onChange={(event) => handleRadioChange(event, label.id)}
                            onFocus={(event) => onFocusRadio(event, label.id)}
                        />
                    </Form.Group>
                // return <h1></h1>
            });
        } else {
            labelList = labels.map((label, index) => {
                if (label.name !== "Approve" && label.name !== "Reject")
                    return <Form.Group controlId={`control-${label.id}-${media.id}`} className="mb-1"
                        key={`group-label-${index}`}>
                        <Form.Check
                            name={`group-${media.id}`}
                            type="radio"
                            label={label.name.replace(/([A-Z])/g, ' $1').trim()}
                            checked={activeLabelId === label.id}
                            onChange={(event) => handleRadioChange(event, label.id)}
                            onFocus={(event) => onFocusRadio(event, label.id)}
                        />
                    </Form.Group>
                // return <h1></h1>
            });
        }

    } else {
        labelList = labels.map((label, index) => (
            <Form.Group controlId={`control-${label.id}-${media.id}`} className="mb-1"
                key={`group-label-${index}`}>
                <Form.Check
                    name={`group-${media.id}`}
                    type="radio"
                    label={label.name}
                    checked={activeLabelId === label.id}
                    onChange={(event) => handleRadioChange(event, label.id)}
                    onFocus={(event) => onFocusRadio(event, label.id)}
                />
            </Form.Group>

        ))
    }

    // useEffect(() => {
    //     if (project.name === projectsConstants.IS1_ALBUM) {
    //         let _isIS1AlbumHash = false;
    //         if (media['note'] != null) {
    //             let media_note = media['note'].split(" | ");
    //             if (media_note[0] != null && media_note[0] != "" && media_note[0] == "album_hash") {
    //                 _isIS1AlbumHash = true;
    //             }
    //         }
    
    //         if (_isIS1AlbumHash) {
    //             setActiveLabelId(labelsIS1Hash[0].id);
    //             debugger;
    //         }
    //     }
    // });

    useEffect(() => {
        // debugger;
        if (position === 0 || 
            (is1AlbumCurrentIndexToShow % 3 == 0 && is1AlbumCurrentIndexToShow - position >= 0 && is1AlbumCurrentIndexToShow - position < 3) ||
            (is1AlbumCurrentIndexToShow % 3 == 2 && is1AlbumCurrentIndexToShow - position >= 0 && is1AlbumCurrentIndexToShow - position < 2) ||
            (is1AlbumCurrentIndexToShow % 3 == 1 && is1AlbumCurrentIndexToShow - position >= 0 && is1AlbumCurrentIndexToShow - position < 1) 
            ) {
            setIs1AlbumIsShowing(true);
        } else {
            setIs1AlbumIsShowing(false);
        }
    }, [is1AlbumCurrentIndexToShow, is1AlbumIsShowing]);

    let mediaClassName = (project.name === projectsConstants.IS1_ALBUM && !is1AlbumIsShowing) ? "hide" : "show";

    return (
        <Col xl={isIS1AlbumHash ? 12 : 4} lg={6} md={6} sm={12} xs={12} className={"single-media " + mediaClassName}>
            <HeaderMediaId media={media} mediaInfo={mediaInfo} project={project} />
            <Watermark media={media} project={project} index={position} currentLabel={currentLabel} onMouseClick={handleMouseClickMedia} />
            <Card className="bg-transparent">
                {preContent}

                <Media media={media} labels={isIS1AlbumHash ? labelsIS1Hash : labels} activeLabelId={activeLabelId} onMouseClick={handleMouseClickMedia}
                    project={project} updateMediaInfo={updateMediaInfo} category={photoCategory(media['media_url'], project)} />

                {postContent}

                {middleContent}
                {/* { middleContent != "" &&
                    (<div>report count: {middleContent}</div>)
                } */}
                
                <Card.Body>
                    <div className="d-flex">
                        <a href={media['media_url']} target="_blank" className="pr-2"
                            rel="noopener noreferrer">
                            <Button className="bg-transparent border-0">Original</Button>
                        </a>
                        <Button onClick={() => openModal(media)} className="bg-transparent border-0">Zoom</Button>
                        {
                            project.name !== projectsConstants.IS1_ALBUM ? 
                            (<ActiveLabelText activeLabelId={activeLabelId} labels={isIS1AlbumHash ? labelsIS1Hash : labels} />) :
                            ("") 
                        }
                    </div>
                    {
                        project.name === projectsConstants.IS1_ALBUM ? 
                        (<ActiveLabelText activeLabelId={activeLabelId} labels={isIS1AlbumHash ? labelsIS1Hash : labels} />) :
                        ("") 
                    }

                    {labelType}
                    <hr className="bg-purple" />
                    {labelList}
                </Card.Body>
            </Card>

            <div className={"note-" + project.name}>{note}</div>
            {/*<ModalMedia show={modalShow === mediaId} onHide={() => setModalShow(null)} media={media} project={project}/>*/}
            
        </Col>
    )
};


const Watermark = ({ media, project, index, currentLabel, onMouseClick }) => {
    if (project.name === "Kids") {
        let currentLabelCss = "";
        if (currentLabel.name === "Reject" || currentLabel.name === "Broken") {
            currentLabelCss = " watermark-" + currentLabel.name;
        }

        return (
            <div id={"watermark-" + media['media_id']} className={"watermark-" + index + currentLabelCss} onClick={onMouseClick} >
                <p className="bg-text">{currentLabel.name}</p>
            </div>
        )
    }

    if (project.name === projectsConstants.STORYTELLING_QUEUE2) {
        let currentLabelCss = "";
        if (currentLabel.name === "Image_Explicit" || currentLabel.name === "Illustration_Explicit") {
            currentLabelCss = " watermark-" + currentLabel.name;
        }

        return (
            <div id={"watermark-" + media['media_id']} className={"watermark-" + index + currentLabelCss} onClick={onMouseClick} >
                <p className={"bg-text-" + currentLabel.name}>{currentLabel.name}</p>
            </div>
        )
    }

    return (
        ""
    )
};
